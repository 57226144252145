export default defineNuxtRouteMiddleware(async (to) => {
  // skip middleware on server
  if (import.meta.server) return

  const routeBaseName = useNuxtApp().$getRouteBaseName(to)
  const loginPath = useNuxtApp().$localePath({ name: 'locale-login' })

  const privateRoutes = [`locale-account`, `locale-checkout`]
  // Private routes
  if (to.name) {
    if (checkPath(routeBaseName, privateRoutes)) {
      const { isLoggedIn } = useAuthUser()
      const logged = await isLoggedIn()
      if (!logged) {
        // TODO, CAPTURAR CUANDO SE ABORTA NAVEGACION PARA MOSTRAR AL USUARIO ALGUN MENSAJE
        return loginPath
      }
    }
  }
})

const checkPath = (name, names: string[]) => {
  for (const n of names) {
    if (name.startsWith(n)) return true
  }
  return false
}
